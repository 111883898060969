/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Projekt realizowany od grudnia 2021 r. pn. „Zabezpieczenie przeciwpowodziowe m. Ostrowiec Świętokrzyski, gm. Ostrowiec Świętokrzyski oraz gm. Bodzechów w oparciu o regulację rzeki Modły, z wykorzystaniem istniejącego zbiornika w Częstocicach jako polderu zalewowego do redukcji fali powodziowej” przewidywał budowę czterech suchych zbiorników przeciwpowodziowych (polderów przepływowych), regulację dwóch odcinków cieków wodnych, przebudowę dwóch przepustów oraz podwyższenie jednego odcinka wału przeciwpowodziowego."), "\n", React.createElement(_components.p, null, "W ramach projektu:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "wybudowano system suchych zbiorników przeciwpowodziowych (polderów zalewowych) z czterema przegrodami piętrzącymi wyposażonymi w spusty denne, których zadaniem będzie retencjonowanie odpływów wywołanych nawałnicami,"), "\n", React.createElement(_components.li, null, "przeprowadzono regulacje (obejmujące odmulenie, pogłębienie oraz ubezpieczenie skarp) rzeki Modły na długości 205 m oraz dopływu spod Mychowa na długości 84 m,"), "\n", React.createElement(_components.li, null, "przebudowano przepusty na rzece,"), "\n", React.createElement(_components.li, null, "podwyższono lewy wał przeciwpowodziowy za pomocą murku oporowego o wysokości 0,5 m od strony międzywala o długości 316 m"), "\n"), "\n", React.createElement(_components.p, null, "Projekt regulacji Modły to niezwykle ważne i oczekiwane przez społeczność lokalną przedsięwzięcie. Realizacja inwestycji znacznie poprawiła bezpieczeństwo mieszkańców Ostrowca Świętokrzyskiego i okolic."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
